<template>
    <div ref="load" class="zt-page-content" style="overflow:unset;">
        <div class="fj-list">
            <Quotation v-if="item" :item="item" ref="getSku" />
        </div>
        <!-- <div class="block add-btn">
            <i class="el-icon-circle-plus-outline"></i>
            <span>继续选择附件</span>
        </div> -->
        <div v-if="item" class="zt-block btn-block">
            <el-button @click="back()" type="primary">取消</el-button>
            <el-button @click="skuSubmit(0)" type="primary">保存</el-button>
            <el-button v-if="!type" @click="submitShow()" type="primary">发布</el-button>
        </div>

        <!-- 选择商品分类弹窗 -->
        <el-dialog width="540px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle=='温馨提示'">
                <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
                    <p style="font-size:16px;color:#292929;">商品发布确认</p>
                    <p style="font-size:14px;color:#808080;">商品发布后，会在集市和销售手册中展示，请仔细核对商品详情</p>
                </div>
                <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
                    <el-button style="margin-right:20px;" @click="submitHide()" size="small" type="primary" plain>再修改一下</el-button>
                    <el-button size="small" type="primary" @click="skuSubmit(1)">确认并发布</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import _ from 'lodash';
import { setStepPrice,getSelfId } from '@/service/factory';
import Quotation from '@/components/factory/Quotation.vue';
export default {
    components: {
        Quotation
    },
    data() {
        return {
            popTitle:"",
            popVisible:false,
            type:null, // type附件sku索引值
            item:null,
            itemId:null,// 附件或者商品的ID
            loading:null,
        }
    },
    created() {
        this.type = this.$route.query.type;
        this.detail();
    },
    mounted() {
        this.loading = Loading.service({
            fullscreen: true, text: '拼命加载中....',
            target:this.$refs.load
        });
    },
    methods:{
        back() {
            this.$router.go(-1);
        },
        detail() {
            getSelfId(this.$route.params.id).then(rst=>{
                if (rst) {
                    this.item=rst;
                    if (this.type) {
                        this.itemId = rst.accessory[this.type].id;
                    } else {
                        this.itemId = rst.id;
                    }
                }
                console.log(this.item);
            });
        },
        submit(obj,type) {
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
            });
            setStepPrice(obj).then(rst => {
                _loading.close();
                this.$message.success(type==1 ? '上传成功' : '保存成功');
                this.back();
            }).catch(err => {
                _loading.close();
                this.$message.error(err.message);
            });
        },
        async skuSubmit(type){
            this.$refs.getSku.valid().then(rst => {
                if (rst && rst.length>0) {
                    let _item = _.clone(rst);
                    // console.log(_item);
                    let obj = {
                        product_no:this.itemId,
                        step_price:_item,
                        publish:0,
                    }
                    type==1 ? obj.publish=1 : obj.publish=0;
                    this.submit(obj,type);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        async submitShow() {
            if (this.$store.state.loginUser.comp.cert_stat!=2) {
                this.$message.error("企业未认证");
                return;
            } else {
                this.$refs.getSku.valid().then(rst => {
                    // console.log(rst);
                    if (rst && rst.length>0) {
                        this.popTitle = "温馨提示";
                        this.popVisible = true;
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
            }
        },
        submitHide() {
            this.popVisible = false;
        },
    },
    updated() {
        this.loading.close();
    },
}
</script>
<style src="../style.css"></style>
<style scoped lang="less">
    .zt-page-content {
        min-height: 800px;
        background: #F5F5F5;
        .add-btn {
            height: 80px;
            color: #016FFF;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0 46px;
            cursor: pointer;
            i {
                font-size: 18px;
                margin-right: 16px;
            }
        }
        .block-btm {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .select-container {
                position: absolute;
                left: 50px;
                display: flex;
                align-items: center;
                span {
                    color: #D70000;
                    font-size: 22px;
                    position: relative;
                    top: 4px;
                }
                b {
                    margin: 0 23px 0 5px;
                }
            }
        }
        .btn-block {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
            .el-button {
                margin-left: 48px;
            }
            a {
                margin-left: 48px;
                margin-right: 10px;
            }
        }
    }
</style>